<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{path:'/illnessManage'}">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item>疾病百科</el-breadcrumb-item>
        </el-breadcrumb>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane class="height50" label="简介" name="0">
                <Editor clsss="changeedit" :desc="form.intro" :ys="'intro'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="症状" name="1">
                <Editor :desc="form.symptom" :ys="'symptom'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="病因" name="2">
                <Editor :desc="form.cause" :ys="'cause'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="诊断" name="3">
                <Editor :desc="form.diagnosis" :ys="'diagnosis'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="治疗" name="4">
                <Editor :desc="form.cure" :ys="'cure'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="生活" name="5">
                <Editor :desc="form.life" :ys="'life'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="预防" name="6">
                <Editor :desc="form.prevention" :ys="'prevention'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="参考文献" name="7">
                <Editor :desc="form.reference" :ys="'reference'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="简要说明" name="8">
                <Editor :desc="form.subtitle" :ys="'subtitle'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
        </el-tabs>
        <p style="height: 50px;"></p>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="sub">保存</el-button>
        <el-button type="danger" @click="del" :disabled="disabled">删除</el-button>
    </div>
</template>
<script>
    import Editor from '../components/Editor'
    export default {
        components: {
            Editor
        },
        inject: ['reload'],
        data() {
            return {
                id: '',
                name: '',
                form: {
                    intro: '',
                    symptom: '',
                    cause: '',
                    diagnosis: '',
                    cure: '',
                    life: '',
                    prevention: '',
                    ill_lib_id: '',
                    reference: '',
                    subtitle:''
                },
                type: '',
                disabled: false,
                mes: '',
                activeName: '0',
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            this.name = this.$route.query.name;
            this.form.ill_lib_id = this.$route.query.id;
            // this.getDetail();
            this.axios.get('/baike/get_gu_baike_ill_by_ill_lib_id?ill_lib_id=' + this.id)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.type = 'edit';
                            this.form = rs.data.result;
                            this.disabled = false;
                        } else {
                            this.type = 'new';
                            this.disabled = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            fathertest: function (content) {
                // childValue就是子组件传过来的值
                let b = content.b;
                for(var key in this.form) { 
                    if(key == b){
                        this.form[key] = content.a;
                    }
                }
            },
            getDetail() {
                this.axios.get('/baike/get_gu_baike_ill_by_ill_lib_id?ill_lib_id=' + this.id)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.type = 'edit';
                            this.form = rs.data.result;
                            this.disabled = false;
                            console.log(this.form)
                        } else {
                            this.type = 'new';
                            this.disabled = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            sub() {
                let url = '', str = '';
                if (this.type == 'new') {
                    url = "/baike/insert_gu_baike_ill";
                    str = '添加成功';
                } else {
                    url = "/baike/update_gu_baike_ill";
                    str = '修改成功';
                }
                console.log(this.form);
                this.axios.post(url, this.qs.stringify(this.form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.$router.push({ path: '/illnessManage' });
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            cancel() {
                this.$router.push({ path: '/illnessManage' });
            },
            del() {
                this.$confirm('此操作将永久删除该疾病百科, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post('/baike/delete_gu_baike_ill', this.qs.stringify({ ill_lib_id: this.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.$router.push({ path: '/illnessManage' });
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
    .ql-container{
        height: 400px;
    }
</style>